<template>
    <div class="dynamic">
        <img class="boximg" src="../assets/dynamic/1.png" alt="" srcset="">
        <div class="dynamiclist">
            <dynamicmode name="幼教天地"  type="0" :imgsrc="img" />
            <dynamicmode name="职业培训" type="1" :imgsrc="img1" />
            <dynamicmode name="产教动态" type="2" :imgsrc="img2" />
            <dynamicmode name="职教论坛" type="3" :imgsrc="img3" />

        </div>
        <div class="dynamicq">
            <div class="dynamicheader">
                <div class="dynamicheaderimg">
                    <img src="../assets/dynamic/icon4.png" alt="">
                </div>
                <div class="dynamicheaderright">
                    <div class="dynamicheaderrighttext">集团服务体系</div>
                    <div class="dynamicheaderrightbt"></div>

                </div>
            </div>
        </div>
        <div class="qrcodebox">
            <div class="qrcode" v-for="(item,index) in list" :key="index">
                <div class="qrcodeimg">
                    <img :src="item.img" alt="">
                </div>
                <div class="qrcodetitle" :style="index<3?'color:#BE413F':''">{{item.title}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import dynamicmode from "../components/dynamicmode.vue";

export default {
    components: {
        dynamicmode,
    },
    data() {
        return {
            img: require("../assets/dynamic/icon.png"),
            img1: require("../assets/dynamic/icon1.png"),
            img2: require("../assets/dynamic/icon2.png"),
            img3: require("../assets/dynamic/icon3.png"),
            list: [
                {
                    img: require("../assets/dynamic/2.png"),
                    title: "标榜集团公众号",
                },
                {
                    img: require("../assets/dynamic/3.png"),
                    title: "一问职培公众号",
                },
                {
                    img: require("../assets/dynamic/4.png"),
                    title: "技能培训中心",
                },
                {
                    img: require("../assets/dynamic/5.png"),
                    title: "一问教资考证",
                },
                {
                    img: require("../assets/dynamic/6.png"),
                    title: "一问抖音号",
                },
                {
                    img: require("../assets/dynamic/7.png"),
                    title: "一问小红书",
                },
            ],
        };
    },
    created(){

    },
    methods:{
        
    }
};
</script>
<style lang="scss" scoped>
.dynamic {
    .boximg {
        width: 100%;
        margin-bottom: 30px;
    }
    .dynamiclist {
        width: 80vw;
        margin: 0px auto 30px;
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 20px;
    }
    .dynamicq {
        width: 80vw;
        margin: 30px auto 30px;
    }
    .dynamicheader {
        display: flex;
        align-items: center;
        .dynamicheaderimg {
            // width: 36px;
            height: 40px;
            margin-right: 10px;
            img {
                // width: 100%;
                height: 40px;
            }
        }
        .dynamicheaderright {
            font-size: 18px;
            font-weight: bold;
            color: #7d1312;
        }
        .dynamicheaderrightbt {
            width: 100%;
            height: 8px;
            border-radius: 8px;
            background: rgba(190, 65, 63, 0.1);
        }
    }
    .qrcodebox {
        width: calc(80vw - 60px);
        margin: 0px auto 30px;
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        grid-gap: 20px;
        padding: 30px;
        background: linear-gradient(to  right, #FFFFFF 0%, rgba(196,220,255,0.3) 40%,#FFFFFF 100%);
        .qrcode {
            .qrcodeimg {
                img {
                    width: 100%;
                }
            }
            .qrcodetitle {
                text-align: center;
                font-size: 25px;
                font-weight: bold;
                margin-top: 10px;
            }
        }
    }
}
</style>