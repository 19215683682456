<template>
    <div class="dynamicmode">
        <div class="dynamicheader">
            <div class="dynamicheaderimg">
                <img :src="imgsrc" alt="">
            </div>
            <div class="dynamicheaderright">
                <div class="dynamicheaderrighttext">{{name}}</div>
                <div class="dynamicheaderrightbt"></div>

            </div>
        </div>
        <div class="swibox">
            <el-carousel style="width:100%" indicator-position="outside" :autoplay="false" height="520px" @change="changeswiper">
                <el-carousel-item class="dynamicmodelist" v-for="(item,index) in list" :key="index" >
                    <div class="modelist" v-for="(items,indexs) in item" :key="indexs" @click="gopath(items)">
                        <div class="modelistimg">
                            <img :src="items.picUrl" alt="">
                        </div>
                        <div class="modelistright">
                            <div class="modelistheader">
                                <div class="modelistblod" :style="indexs==0?'color:#BE413F':''">{{items.title}}</div>
                                <div class="modelistdes">{{items.briefIntroduction}}</div>
                            </div>
                            <div class="modelistdate">{{items.createdTime}} 发布</div>
                        </div>
                    </div>
                </el-carousel-item>

            </el-carousel>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        name: String,
        imgsrc: String,
        type: String,
    },
    data() {
        return {
            list: [],
            page: 1,
            pageSize: 3,
        };
    },
    created() {
        this.getGroupDynamics();
    },
    methods: {
        gopath(item) {
            this.$router.push({path:'/dynamicinfo',query:{id:item.id}});
        },
        changeswiper(i){
            console.log(i);
             this.$api.dynamic.getGroupDynamics({
                type: this.type,
                page:i+1,
                pageSize:this.pageSize
            }).then(res=>{
                this.list[i] = JSON.parse(JSON.stringify(res.data.result.list));
                this.$forceUpdate()
            });
        },
        getGroupDynamics() {
            this.$api.dynamic.getGroupDynamics({
                type: this.type,
                page:this.page,
                pageSize:this.pageSize
            }).then(res=>{
                for (let index = 0; index <res.data.result.totalPageNum; index++) {
                     this.list.push([])  
                }
                this.list[0] = res.data.result.list
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.dynamicmode {
    // background: #ffffff;
    box-shadow: 0px 3px 12px rgba(8, 65, 144, 0.1);
    border-radius: 20px;
    padding: 20px;
    .dynamicheader {
        display: flex;
        align-items: center;
        .dynamicheaderimg {
            // width: 36px;
            height: 40px;
            margin-right: 10px;
            img {
                // width: 100%;
                height: 40px;
            }
        }
        .dynamicheaderright {
            font-size: 18px;
            font-weight: bold;
            color: #7d1312;
        }
        .dynamicheaderrightbt {
            width: 100%;
            height: 8px;
            border-radius: 8px;
            background: rgba(190, 65, 63, 0.1);
        }
    }
    .swibox {
        /deep/.el-carousel__button {
            background: #be413f;
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }
        .dynamicmodelist {
            // margin-top: 20px;
            .modelist {
                display: flex;
                margin-top: 20px;
                padding-bottom: 20px;
                border-bottom: 1px dashed #d9d9d9;
                .modelistimg {
                    width: 200px;
                    height: 130px;
                    margin-right: 14px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .modelistright {
                    width: calc(100% - 220px);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .modelistheader {
                        font-size: 18px;
                        .modelistblod {
                            font-weight: bold;
                            color: #000;
                        }
                        .modelistdes {
                            color: #606060;
                        }
                    }
                    .modelistdate {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.59);
                    }
                }
            }
        }
        .dynamicmodelist > .modelist:first-child {
            border-bottom: 1px dashed #be413f;
        }
    }
}
</style>